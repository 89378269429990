import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { fetchResponse, fetchResponseWithBody,SHOPREFID } from '../../restapiutil/RestAPIClient';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { deleteOrder } from '../Reservierung/RestService';
import { Editor } from '@tinymce/tinymce-react';
class MittagsBuffetAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {list:[],currentItem:"upcoming",text:""};
       

    }

   
     log = () => {
      if (this.state.text.getContent()) {
        console.log(this.state.text.getContent());
        console.log(encodeURI(this.state.text.getContent()));
      }
    };

    async componentDidMount (){
         this.getOrders("upcoming");
       
    }

    async  getOrders(param) {
        const APPLICATION_JSON = "application/json";
        var dataurl=null;
        if(param == null){
            param = this.state.currentItem;
        }else{
            this.setState({currentItem : param });
        }
        if(param == null || param == "" || param == "upcoming"){
            dataurl = "reserve/"+SHOPREFID+"?isUpcoming=true";
        }else if(param == "past"){
            dataurl = "reserve/"+SHOPREFID+"?isPast=true";
        }else if(param == "today"){
            dataurl = "reserve/"+SHOPREFID+"?isToday=true";
        }else if(param == "new"){
            dataurl = "reserve/"+SHOPREFID+"?status=1";
        }else if(param == "accepted"){
            dataurl = "reserve/"+SHOPREFID+"?status=2";
        }else if(param == "rejected"){
            dataurl = "reserve/"+SHOPREFID+"?status=3";
        }else if(param == "deleted") {
            dataurl = "reserve/"+SHOPREFID+"?status=5";
        }else{
            console.log(param);
        }
        const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON };
        const response = await fetchResponse(dataurl,"GET",headers,{});
        if(response != null){
            this.setState({list:response.responseData});
            console.log(this.state);
        }
    }

    async acceptOrder(obj){
        const dataurl = "reserve/confirm";
        const APPLICATION_JSON = "application/json";
        const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
        await fetchResponseWithBody(dataurl,"POST",headers,{},{id:obj.id,shopRefId: obj.shopRefId});
        this.getOrders();
    }

    async rejectOrder(obj){
        const dataurl = "reserve/reject";
        const APPLICATION_JSON = "application/json";
        const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
        await fetchResponseWithBody(dataurl,"POST",headers,{},{id:obj.id,shopRefId: obj.shopRefId});
        this.getOrders();
    }

    async deleteOrder(obj){
        deleteOrder(obj); 
        this.getOrders();
    }

    getList(){
       const arrayDataItems = this.state.list.map((obj) =>  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}><Card  raised={true} 
       sx={{ backgroundColor: obj.status=='PENDING'?'red':obj.status=='CONFIRMED'?'#26d526':'#FFFFFF' }}> 
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {obj.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Date : {obj.date}  &nbsp; Time : {obj.time}
                <br/>Email : {obj.email}
                <br/>Party No : {obj.partyNo}
                <br/>Phone : {obj.phone}
                <br/>Message : {obj.message}
            </Typography>
        </CardContent>
        <CardActions>
        { obj.status=='PENDING' ? <Button size="small" onClick={ (e) => this.acceptOrder(obj)}>Accept</Button> : null }
            
            <Button size="small" onClick={ (e) => this.rejectOrder(obj)}>Reject</Button>
            <Button size="small" onClick={ (e) => this.deleteOrder(obj)}>Delete</Button>
        </CardActions>
    </Card></Grid>);
    return arrayDataItems;
    }
     


    render() {
       
        return (
            <div class="continer-fluid p-3">
                <div class='row'>
                    <div class="col-md-12">
                    <FormControl>
      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
      <Editor
        apiKey='bcsaq50fopl6mn2dlldgdf5ehwottaeujt32j2d0x8hhnpbl'
        onInit={(_evt, editor) => this.state.text = editor}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
            <button onClick={this.log}>Log editor content</button>

    </FormControl>
                    
                        
                    </div>
                </div>
            </div>

        );
    };
}

export default MittagsBuffetAdmin;